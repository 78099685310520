<template>
  <div>
    <!-- 一般頁面 -->
    <div class="general">
      <div class="row">
        <!-- Logo -->
        <div class="col-md-5 col-12 logo-area">
          <img class="logo" src="@/assets/website-use/logo_1.png" alt="logo" />
          <img
            class="logo-text"
            src="@/assets/website-use/EASY-TO-GO.png"
            alt="EasyToGo"
          />
          <p class="logo-slogan">
            免費加入 超好上手
            <br />
            趕快加入我們吧!
          </p>
        </div>
        <!-- 登入/註冊/忘記密碼 -->
        <div class="col-md-7 col-12 form-area">
          <!-- 正式機登入 -->
          <router-view v-if="merchine === 'online'"></router-view>
          <!-- 開發機/測試機登入 -->
          <div class="h-100" v-else>
            <!-- 其他人看到的登入頁 -->
            <div class="position-relative h-100" v-if="status === 'normal'">
              <p class="fw-bolder text-danger mb-3" style="font-size: 30px">
                Oops !
              </p>
              <p class="fw-bolder mb-5">您誤闖易團GO神秘空間 ˊ ˋ</p>
              <a
                class="fw-bolder"
                href="http://www.twingo.com.tw"
                target="_blank"
                rel="noopener noreferrer"
                style="font-size: 30px"
              >
                👉 點我前往正確網址 ~
              </a>
              <p
                class="pointer fw-bolder text-primary position-absolute bottom-0 end-0"
                @click="status = 'login'"
              >
                工作人員點此登入>>
              </p>
            </div>
            <!-- 職員登入 -->
            <div v-else>
              <form class="mb-3" @submit.prevent="staffLogin">
                <p class="title">職員登入</p>
                <!-- 帳號 -->
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="Account"
                    placeholder="帳號"
                    v-model="login.account"
                  />
                  <label for="Account">帳號</label>
                </div>
                <!-- 密碼 -->
                <div class="form-floating mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="Password"
                    placeholder="密碼"
                    v-model="login.password"
                    maxlength="40"
                  />
                  <label for="Password">密碼</label>
                </div>
                <button class="form-btn" type="submit">登入</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer class="footer">
      <div class="row pt-2">
        <p class="col-md-4 col-6 mb-2">LINE客服: @etwingo</p>
        <p class="col-md-4 col-6 mb-2">客服時間: 09:30-18:00</p>
        <p class="col-md-4 col-12 mb-1">
          聯絡信箱:mailto:twingoservice@twingo.com.tw
        </p>
      </div>
      <div class="company row py-1">
        <p class="col-md-4 col-12 mb-md-0 mb-2">
          ©2022~2023優碼興科技股份有限公司
        </p>
        <a class="col-md-4 col-6 pointer" @click="go('/ETwingo/privacy')"
          >隱私權政策</a
        >
        <a class="col-md-4 col-6 pointer" @click="go('/ETwingo/serve')"
          >服務條款</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      merchine: process.env.VUE_APP_MERCHINESTATUS,
      status: 'normal', // normal: 一般人在"開發機"/"測試機"看到的畫面，login: 職員登入
      login: {
        account: '',
        password: '',
      },
    }
  },
  created() {
    if (localStorage.getItem('checkOtherSystemLogin') && localStorage.getItem('checkOtherSystemLogin') === 'true') this.merchine = 'online'
  },
  methods: {
    go(where) {
      window.open(`${process.env.VUE_APP_ORIGINURL}${where}`, "_blank")
    },
    staffLogin() {
      if (this.login.account !== 'uniten' || this.login.password !== 'uniten1078') return this.SweetAlert('other', '帳號或密碼錯誤')
      localStorage.setItem('checkOtherSystemLogin', true)
      this.merchine = 'online'
    },
  },
}
</script>